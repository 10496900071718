<section class="h-full flex flex-col justify-between items-center">
  <div class="w-full flex flex-col mt-44 box-border px-8">
    <div class="flex flex-col gap-2 w-full">
      <h1 class="my-0 text-3xl font-semibold">Welcome back</h1>
      <h2 class="my-0 text-lg text-grey_900 font-normal">Enter your email and password to continue.</h2>
    </div>
    <form class="flex flex-col gap-2" [formGroup]="user" (keyup.enter)="login()">
      <p class="text-color_reject">
        @if (showError) {
          {{ error }}
        }
      </p>
      <div class="mt-4">
        <app-floating-input
          [disabled]="!!invitationToken"
          ngDefaultControl
          label="Email"
          placeholder="Email"
          formControlName="email"
        >
        </app-floating-input>
        <app-floating-input
          ngDefaultControl
          type="password"
          formControlName="password"
          label="Password"
          placeholder="Password"
        >
        </app-floating-input>
      </div>
      <a [routerLink]="'/auth/reset-password'" class="text-grey_900 text-right">Forgot password?</a>
      <button
        type="submit"
        class="be-button"
        [disabled]="user.invalid || loading"
        (click)="login()"
        data-cy="login-button"
      >
        Login
      </button>
    </form>
  </div>
  <div class="flex justify-center items-center gap-1">
    <p class="text-grey_900 font-normal">Don't have an account yet?</p>
    <a routerLink="/auth/register" class="text-color_secondary font-semibold no-underline">Register</a>
  </div>
</section>
